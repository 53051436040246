/* purgecss start ignore */

$page-transitions: 'main-menu', 'sub-menu';

@each $page-transition in $page-transitions{

    .#{$page-transition}-enter {
        &.absolute-page-transition {
            position: fixed;
            top: 0;
            left: 0;
        }

        .#{$page-transition}\:slide-from-left {
            transform: translateX(-10000px);
            transition: transform .5s;
        }

        .#{$page-transition}\:slide-from-right {
            transform: translateX(10000px);
            transition: transform .5s;
        }

        .#{$page-transition}\:slide-from-down {
            transform: translateY(10000px);
            transition: transform .5s;
        }

        .#{$page-transition}\:fade-in {
            opacity: 0;
            transition: opacity .5s;
        }
    }

    .#{$page-transition}-enter-active {
        .#{$page-transition}\:slide-from-left {
            transform: translateX(0);
        }

        .#{$page-transition}\:slide-from-right {
            transform: translateX(0);
        }

        .#{$page-transition}\:slide-from-down {
            transform: translateY(0);
        }

        .#{$page-transition}\:fade-in {
            opacity: 1;
        }
    }

    .#{$page-transition}-enter-done {
        .#{$page-transition}\:slide-from-left {
            transform: translateX(0);
        }

        .#{$page-transition}\:slide-from-right {
            transform: translateX(0);
        }

        .#{$page-transition}\:slide-from-down {
            transform: translateY(0);
        }

        .#{$page-transition}\:fade-in {
            opacity: 1;
        }
    }



    .#{$page-transition}-exit {
        &.absolute-#{page-transition}-transition {
            position: absolute;
            top: 0;
            left: 0;
        }

        .#{$page-transition}\:slide-from-left{
            transition: transform .5s ease-in;
            transform: translateX(0);
        }

        .#{$page-transition}\:slide-from-right{
            transition: transform .5s ease-in;
            transform: translateX(0);
        }

        .#{$page-transition}\:slide-from-down {
            transition: transform .5s ease-in;
            transform: translateY(0);
        }

        .#{$page-transition}\:fade-in {
            transition: opacity .5s ease-in;
            opacity: 1;
        }
    }

    .#{$page-transition}-exit-active {
        .#{$page-transition}\:slide-from-left {
            transform: translateX(-10000px);
        }

        .#{$page-transition}\:slide-from-down {
            transform: translateY(10000px);
        }

        .#{$page-transition}\:fade-in {
            opacity: 0;
        }

        .#{$page-transition}\:slide-from-right{
            transform: translateX(10000px);
        }

    }
}

$general-transitions: '-enter', 'enter-active', 'exit', 'exit-active';

@each $transition in $general-transitions {
    [class$=#{$transition}] .transition-hidden {
        display: none;
    }
}

.shake-it {
    animation: shake-it .1s 3 ease-in-out;
}

.fade-it {
    animation: fade-it .1s infinite ease-in-out;
}

.sub-menu {
    &-enter &\:shrink-in {
        opacity: 1;
        padding-top: 60px;
        height: 75%;
        transition: height .5s, padding-top .5s;
        z-index: 10000;
    }

    &-enter-active  &\:shrink-in {
        opacity: 1;
        height: 50px;
        padding-top: 0.5rem;
    }

    &-exit &\:shrink-in {
        opacity: 1;
        height: 50px;
        padding-top: 0.5rem;
        transition: height .5s, padding-top .5s, opacity .5s cubic-bezier(1,0,1,0);
        z-index: 10000;
    }

    &-exit-active  &\:shrink-in {
        opacity: 0;
        padding-top: 60px;
        height: 75%;
    }

}
page-enter\:shrink-in {
    opacity: 1;
    transition: height .5s;
}

@keyframes shake-it {
    0% {
      transform: translateX(-10px)
    }

    100% {
      transform: translateX(10px)
    }
  }

  @keyframes fade-it {
    0% {
      opacity: .25
    }

    100% {
      opacity: .75
    }
  }

/* purgecss end ignore */

